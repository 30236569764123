
import TheHeader from "~/components/main/TheHeader";
import TheFooter from "~/components/main/TheFooter";
import { mapGetters } from "vuex";
import emptyData from "~/components/main/emptyData";
export default {
  name: "register-view",
  auth: false,

  components: {
    TheHeader,
    TheFooter,
    emptyData,
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      titleTemplate: `%s - ${this.browserInfo.site_name}`,
      link: [
        {
          hid: "favicon",
          rel: "icon",
          type: "image/png",
          href: `${this.browserInfo.browser_logo}`,
        },
      ],

      meta: [
        {
          hid: "description",
          name: "description",
          content: `${this.browserInfo.seo_desc}`,
        },
        {
          hid: "keywords",
          name: "keywords",
          content: `${this.browserInfo.seo_keys}`,
        },
      ],
    };
  },
  data() {
    return {
      requstFail: false,
      refresh: true,
      browserInfo: {},
      emptyData: {
        image: "fail.png",
        head: this.$i18n.t("Sorry"),
        paragraph: this.$i18n.t("Something_went_wrong_Please_try_again"),

        button: {
          url: "window.location.reload",
          name: this.$i18n.t("Try_again"),
        },
      },
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["getRequstFail"]),
    ...mapGetters("settingsModule", ["getBrowserInfo"]),
  },
  mounted() {
    this.requstFail = this.getRequstFail;
    this.browserInfo = this.getBrowserInfo;
  },
  watch: {
    getRequstFail() {
      this.requstFail = this.getRequstFail;
    },
    getBrowserInfo() {
      this.browserInfo = this.getBrowserInfo;
    },
  },
};
