import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const vendorsModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      vendors: [],
      vendorDetails: {},
      vendorProducts: [],
      isMore: false,
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getVendors: (state) => state.vendors,
    getVendorDetails: (state) => state.vendorDetails,
    getVendorProducts: (state) => state.vendorProducts,
    getIsMore: (state) => state.isMore,
  },
  mutations: {
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setVendors: (state, payload) => {
      state.vendors = payload;
    },
    SET_MORE_VENDORS: (state, payload) => {
      state.vendors.push(...payload);
    },
    SET_IS_MORE: (state, payload) => {
      state.isMore = payload;
    },

    setVendorDetails: (state, payload) => {
      state.vendorDetails = payload;
    },
    setVendorProducts: (state, payload) => {
      state.vendorProducts = payload;
    },
    SET_MORE_VENDOR_PRODUCTS: (state, payload) => {
      state.vendorProducts.push(...payload);
    },
  },
  actions: {
    async vendorsList({ commit }) {
      commit("setIsLoading", true);
      await this.$axios
        .$get("vendors")
        .then((data) => {
          commit("setIsLoading", false);
          commit("setVendors", data.data);
          commit("setPaginationLinks", data.meta);
        })
        .catch((error) => {
          console.log(error);
          errorAdded(error);
        });
    },
    async vendorById({ commit }, payload) {
      commit("setIsLoading", true);

      await this.$axios
        .$get(`vendors/${payload}`)
        .then((data) => {
          console.log(data.data);
          commit("setIsLoading", false);

          commit("setVendorDetails", data.vendor);
          commit("setVendorProducts", data.data);
          commit("setPaginationLinks", data.meta);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    async vendorProductsSort({ commit }, payload) {
      await this.$axios
        .$get(`vendors/products/${payload.id}?filter=${payload.filterMethod}`)
        .then((data) => {
          console.log(data.data);
          commit("setVendorProducts", data.data);
          commit("setPaginationLinks", data.meta);
        })
        .catch((error) => console.log(error));
    },

    async loadMore({ commit }, payload) {
      return await this.$axios
        .$get(`vendors?page=${payload}`)
        .then((data) => {
          commit("SET_MORE_VENDORS", data.data);
          commit("SET_IS_MORE", data.next);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    async loadMoreProducts({ commit }, payload) {
      return await this.$axios
        .$get(`vendors/${payload.id}?page=${payload.page}`)
        .then((data) => {
          commit("SET_MORE_VENDOR_PRODUCTS", data.data);
          commit("SET_IS_MORE", data.next);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
  },
};

export default vendorsModule;
