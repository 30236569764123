
import TheHeader from "~/components/main/TheHeader";
import TheFooter from "~/components/main/TheFooter";
import { mapActions, mapGetters } from "vuex";
import emptyData from "~/components/main/emptyData";

export default {
  name: "defalut-view",
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      titleTemplate: `%s - ${this.browserInfo.site_name}`,
      link: [
        {
          hid: "favicon",
          rel: "icon",
          type: "image/png",
          href: `${this.browserInfo.browser_logo}`,
        },
      ],

      meta: [
        {
          hid: "description",
          name: "description",
          content: `${this.browserInfo.seo_desc}`,
        },
        {
          hid: "keywords",
          name: "keywords",
          content: `${this.browserInfo.seo_keys}`,
        },
      ],
    };
  },
  components: {
    TheHeader,
    TheFooter,
    emptyData,
  },
  data() {
    return {
      requstFail: false,
      refresh: true,
      browserInfo: {},

      emptyData: {
        image: "fail.png",
        head: this.$i18n.t("Sorry"),
        paragraph: this.$i18n.t("Something_went_wrong_Please_try_again"),

        button: {
          url: "window.location.reload",
          name: this.$i18n.t("Try_again"),
        },
      },
    };
  },
  methods: {
    ...mapActions("guestModule", ["generateToken", "fetchCartProducts"]),
  },
  created() {},
  computed: {
    ...mapGetters(["getRequstFail"]),
    ...mapGetters("settingsModule", ["getBrowserInfo"]),
    ...mapGetters("userModule", ["getIsAuth"]),
  },
  mounted() {
    this.requstFail = this.getRequstFail;
    this.browserInfo = this.getBrowserInfo;
    this.isAuth = this.getIsAuth;

    if (!this.isAuth) {
      const guestToken = this.$cookies.get("X-Guest-Token");
      if (guestToken == undefined) this.generateToken();
      else this.fetchCartProducts();
    }
  },
  watch: {
    getRequstFail() {
      this.requstFail = this.getRequstFail;
    },
    getBrowserInfo() {
      this.browserInfo = this.getBrowserInfo;
    },
  },
};
