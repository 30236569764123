
export default {
  name: "TheFooter",
  async fetch() {
    this.footerInfo = await this.$axios
      .$get("setting/main-data")
      .then((res) => res.data);
  },
  data() {
    return {
      footerInfo: {},
    };
  },
  methods: {},
  async created() {},
  computed: {},
  mounted() {},

  watch: {},
};
