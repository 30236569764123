import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const walletModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      wallet: {},
      bankList: [],
      errors: {
        bank_name: [],
        amount: [],
        bank_account_name: [],
        bank_account_iban: [],
      },
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getWalletCredit: (state) => state.wallet,
    getBankList: (state) => state.bankList,
    getErrors: (state) => state.errors,
  },
  mutations: {
    // Loading
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setWalletCredit: (state, payload) => {
      state.wallet = payload;
    },
    setErrors: (state, payload) => {
      state.errors = payload;
    },
    SET_BANK_LIST: (state, payload) => {
      state.bankList = payload;
    },
  },
  actions: {
    async walletCredit({ commit }) {
      commit("setIsLoading", true);
      await this.$axios
        .$get("wallet/data")
        .then((data) => {
          commit("setIsLoading", false);
          commit("setWalletCredit", data.data);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    async cashWithdrawal({ commit }, payload) {
      await this.$axios
        .$post("cash-withdraw-request", payload)
        .then((data) => {
          successfulAdded(data.message);
        })
        .catch((error) => {
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          } else if (error.response.status == 422) {
            commit("setErrors", error.response.data.data);
          } else {
            errorAdded(error.response.data.message);
          }
        });
    },
  },
};

export default walletModule;
