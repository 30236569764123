const sliderModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      slider: [],
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getSlider: (state) => state.slider,
  },
  mutations: {
    // Loading
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setSlider: (state, payload) => {
      state.slider = payload;
    },
  },
  actions: {},
};

export default sliderModule;
