const blogModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      blog: [],
      articalDetails: {},
      isMore: false,
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getBlog: (state) => state.blog,
    getArticalDetails: (state) => state.articalDetails,
    getIsMore: (state) => state.isMore,
  },
  mutations: {
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setBlog: (state, payload) => {
      state.blog = payload;
    },
    SET_MORE_ARTICLES: (state, payload) => {
      state.blog.push(...payload);
    },
    SET_IS_MORE: (state, payload) => {
      state.isMore = payload;
    },

    setArticalDetails: (state, payload) => {
      state.articalDetails = payload;
    },
  },
  actions: {
    blogList({ commit }) {
      commit("setIsLoading", true);
      this.$axios
        .$get("blog")
        .then((data) => {
          commit("setIsLoading", false);
          commit("setBlog", data.data);
        })
        .catch((error) => {
          commit("setIsLoading", false);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
    articalById({ commit }, payload) {
      commit("setIsLoading", true);
      this.$axios
        .$get(`blog/${payload}`)
        .then((data) => {
          commit("setIsLoading", false);
          commit("setArticalDetails", data.data);
        })
        .catch((error) => {
          commit("setIsLoading", false);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },

    async loadMore({ commit }, payload) {
      return await this.$axios
        .$get(`blog?page=${payload}`)
        .then((data) => {
          commit("SET_MORE_ARTICLES", data.data);
          commit("SET_IS_MORE", data.next);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
  },
};

export default blogModule;
