import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const categoriesModule = {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      isFirstLoad: true,
      categories: [],
      categoriesInfo: {},
      categoryProducts: [],
      categoriesAside: [],
      favProducts: [],
      isMore: false,
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getCategories: (state) => state.categories,
    getCategoryProducts: (state) => state.categoryProducts,
    getFavProducts: (state) => state.favProducts,
    getCategoryInfo: (state) => state.categoriesInfo,
    getIsMore: (state) => state.isMore,

    //
    getCategoriesAside: (state) => state.categoriesAside,
  },
  mutations: {
    // Loading
    setCategoriesAside: (state, payload) => {
      state.categoriesAside = payload;
    },
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    setCategories: (state, payload) => {
      state.categories = payload;
    },
    setCategories: (state, payload) => {
      state.categories = payload;
    },
    setCategoryProducts: (state, payload) => {
      state.categoryProducts = payload;
    },
    setFavProducts: (state, payload) => {
      state.favProducts = payload;
    },
    SET_IS_FAV: (state, payload) => {
      let cat_Index = state.categories.findIndex(
        (category) => category.id == payload.category_id
      );
      let pro_Index = state.categories[cat_Index].products.findIndex(
        (product) => product.id == payload.product_id
      );
      state.categories[cat_Index].products[pro_Index].is_favorite = 1;
    },
    SET_IS_NOT_FAV: (state, payload) => {
      console.log("remove", payload);

      let cat_Index = state.categories.findIndex(
        (category) => category.id == payload.category_id
      );
      console.log("cat_Index", cat_Index);

      let pro_Index = state.categories[cat_Index].products.findIndex(
        (product) => product.id == payload.product_id
      );
      state.categories[cat_Index].products[pro_Index].is_favorite = 0;
    },
    setRemoveFromFavProducts: (state, payload) => {
      let index = state.favProducts.findIndex(
        (product) => product.id == payload.product_id
      );

      state.favProducts.splice(index, 1);
    },

    setCategoryInfo: (state, payload) => {
      state.categoriesInfo = payload;
    },

    SET_MORE_PRODUCTS: (state, payload) => {
      state.categoryProducts.push(...payload);
    },
    SET_IS_MORE: (state, payload) => {
      state.isMore = payload;
    },
  },
  actions: {
    addProductToFav({ commit }, payload) {
      this.$axios
        .$post(`favorite/add`, payload)
        .then((data) => {
          commit("SET_IS_FAV", payload);
          successfulAdded(data.message);
          console.log("data", data);
        })
        .catch((error) => {
          errorAdded("error");
        });
    },
    removeProductToFav({ commit }, payload) {
      this.$axios
        .$post(`favorite/delete/${payload.product_id}`)
        .then((data) => {
          commit("SET_IS_NOT_FAV", payload);
          commit("setRemoveFromFavProducts", payload);
          successfulAdded(data.message);
        })
        .catch((error) => {
          errorAdded(error);
        });
    },
    favoriteList({ commit }) {
      commit("setIsLoading", true);
      this.$axios
        .$get(`favorite`)
        .then((data) => {
          commit("setIsLoading", false);
          commit("setFavProducts", data.data);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },

    async loadMore({ commit }, payload) {
      return await this.$axios
        .$get(`categories/products/${payload.id}?page=${payload.page}`)
        .then((data) => {
          commit("SET_MORE_PRODUCTS", data.data.products);
          commit("SET_IS_MORE", data.data.next);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
  },
};

export default categoriesModule;
