import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const commonQuestions = {
  namespaced: true,
  state() {
    return {
      questionsList: {},
    };
  },
  getters: {
    getQuestionsList: (state) => state.questionsList,
  },
  mutations: {
    setQuestionsList: (state, payload) => {
      state.questionsList = payload;
    },
  },
  actions: {
    async allSettings({ commit }) {
      this.$axios
        .$get("qnas")
        .then((data) => {
          commit("setQuestionsList", data.data);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
  },
};

export default commonQuestions;
