import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const productsModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      isFirstLoad: true,
      products: [],
      selectedProduct: {},
      buttonLoading: false,
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getProducts: (state) => state.products,
    getSelectedProduct: (state) => state.selectedProduct,
    getButtonLoading: (state) => state.buttonLoading,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    setButtonLoading: (state, payload) => {
      state.buttonLoading = payload;
    },
    setProducts: (state, payload) => {
      state.products = payload;
    },
    setSelectedProduct: (state, payload) => {
      state.selectedProduct = payload;
    },
    //
    SET_IS_FAV: (state) => {
      state.selectedProduct.is_favorite = 1;
    },
    SET_IS_NOT_FAV: (state) => {
      state.selectedProduct.is_favorite = 0;
    },
  },
  actions: {
    productById({ commit }, payload) {
      commit("setLoading", true);
      this.$axios
        .$get(`products/${payload}`)
        .then((data) => {
          commit("setSelectedProduct", data.data);
          commit("setLoading", false);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    makeReview({ commit }, payload) {
      commit("setButtonLoading", true);
      this.$axios
        .$post(`products/add-review`, payload)
        .then((data) => {
          console.log(data);
          commit("setLoading", false);
          commit("setButtonLoading", false);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    addProductToFav({ commit }, payload) {
      this.$axios
        .$post(`favorite/add`, payload)
        .then((data) => {
          commit("SET_IS_FAV");
          successfulAdded(data.message);
          console.log("data", data);
        })
        .catch((error) => {
          errorAdded("error");
        });
    },
    removeProductToFav({ commit }, payload) {
      this.$axios
        .$post(`favorite/delete/${payload.product_id}`)
        .then((data) => {
          commit("SET_IS_NOT_FAV");
          successfulAdded(data.message);
        })
        .catch((error) => {
          errorAdded(error);
        });
    },
  },
};

export default productsModule;
