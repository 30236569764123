
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "TheHeader",
  async fetch() {
    this.headInfo = await this.$axios
      .$get("setting/main-data")
      .then((res) => res.data);
    this.countries = await this.$axios
      .$get("country/all")
      .then((res) => res.data);
  },

  data() {
    return {
      isShow: false,
      isShowLanguage: false,
      isShowLanguageEnglish: false,
      isAuth: false,
      open: false,
      cart: [],
      user: {},
      searchValue: "",
      searchResult: [],
      selectedCountry: {},
      countries: [],
      isShowLanguageDefolt: true,
      isSearchNav: false,
      headInfo: {},
    };
  },
  methods: {
    ...mapActions("guestModule", ["generateToken"]),
    ...mapActions("cartModule", ["allProductsCart"]),
    ...mapActions("userModule", ["changeUserLanguage", "userSearching"]),
    ...mapActions("settingsModule", ["browserSettings"]),
    ...mapMutations("userModule", ["setIsAuth", "setUserInfo", "setToken"]),
    ...mapMutations("productsModule", ["setSelectedProduct"]),
    ...mapMutations("cartModule", ["setProductsCart"]),

    closeNavCollapse() {
      const menuToggle = document.getElementById("navbarSupportedContent1");
      const bsCollapse = new bootstrap.Collapse(menuToggle, { toggle: false });
      bsCollapse.toggle();
    },

    changeCoutrySelect(country) {
      localStorage.setItem("country", country.code);
      this.selectedCountry = country;
    },

    setFromState(product) {
      this.setSelectedProduct(product);
    },

    searchingProduct() {
      this.userSearching(this.searchValue);
    },

    changeLanguage(lang) {
      localStorage.setItem("lang", lang);
      this.browserSettings();
    },

    async handleLogout() {
      try {
        await this.$auth.logout("laravelJWT");
        // Send data To Store
        this.setIsAuth(false);
        this.setUserInfo({});
        this.setProductsCart([]);
        localStorage.setItem("lang", "ar");
        // Alert Success

        if (this.$route.path !== "/") {
          if (this.$i18n.locale == "en")
            this.$router.replace(`/${this.$i18n.locale}/`);
          else this.$router.replace(`/`);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.generateToken();
      }
    },
  },
  async created() {
    this.setIsAuth(this.$store.state.auth.loggedIn);
    if (this.$store.state.auth.loggedIn) {
      this.setUserInfo(this.$store.state.auth.user.data);
      await this.allProductsCart();
    }
  },
  computed: {
    ...mapGetters("cartModule", ["getProductsCart"]),
    ...mapGetters("userModule", [
      "getIsAuth",
      "getUserInfo",
      "getSearchingProduct",
    ]),
  },
  mounted() {
    this.cart = this.getProductsCart;
    this.isAuth = this.getIsAuth;
    this.user = this.getUserInfo;
    this.searchResult = this.getSearchingProduct;
  },
  watch: {
    getProductsCart() {
      this.cart = this.getProductsCart;
    },
    getIsAuth() {
      this.isAuth = this.getIsAuth;
    },
    getUserInfo() {
      this.user = this.getUserInfo;
    },
    getSearchingProduct() {
      this.searchResult = this.getSearchingProduct;
    },
  },
};
