import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const requestsModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      requests: [],
      requsetDetails: {},
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getRequests: (state) => state.requests,
    getRequestDetails: (state) => state.requsetDetails,
  },
  mutations: {
    // Loading
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setRequests: (state, payload) => {
      state.requests = payload;
    },
    setRequestDetails: (state, payload) => {
      state.requsetDetails = payload;
    },
  },
  actions: {
    async requestsList({ commit }) {
      commit("setIsLoading", true);
      await this.$axios
        .$get("order/my-orders")
        .then((data) => {
          commit("setRequests", data.data);
          commit("setIsLoading", false);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    async reoderRequest({ commit }) {
      await this.$axios
        .$post(`cart/products/${payload}`)
        .then((data) => {
          console.log(data.data);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          if (error.response.status == 406) {
            errorAdded(error.response.message);
          }
        });
    },
    async requestByd({ commit }, payload) {
      commit("setIsLoading", true);
      await this.$axios
        .$get(`order/order-deatails/${payload}`)
        .then((data) => {
          commit("setRequestDetails", data.data);
          commit("setIsLoading", false);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
  },
};

export default requestsModule;
