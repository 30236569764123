
export default {
  name: "empty-data",

  props: {
    showButton: {
      type: Boolean,
      defalut: true,
    },
    emptyData: {
      type: Object,
      defalut: {},
    },
    refresh: {
      type: Boolean,
      defalut: false,
    },
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
  },
};
