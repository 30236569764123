const settingsModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      settings: [],
      countries: [],
      slider: [],
      contactInfo: {},
      footerInfo: {},
      browserInfo: {},
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getSettings: (state) => state.settings,
    getCountries: (state) => state.countries,
    getSlider: (state) => state.slider,
    getContactInfo: (state) => state.contactInfo,
    getFooterInfo: (state) => state.footerInfo,
    getBrowserInfo: (state) => state.browserInfo,
  },
  mutations: {
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setSettings: (state, payload) => {
      state.settings = payload;
    },
    setCountries: (state, payload) => {
      state.countries = payload;
    },
    setSlider: (state, payload) => {
      state.slider = payload;
    },
    setContactInfo: (state, payload) => {
      state.contactInfo = payload;
    },
    setFooterInfo: (state, payload) => {
      state.footerInfo = payload;
    },
    SET_BROWSER_INFO: (state, payload) => {
      state.browserInfo = payload;
    },
  },
  actions: {
    countriesList({ commit }) {
      commit("setIsLoading", true);
      this.$axios
        .$get("country/all")
        .then((data) => {
          commit("setIsLoading", false);
          commit("setCountries", data.data);
        })
        .catch((error) => {
          commit("setIsLoading", false);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
    browserSettings({ commit }) {
      this.$axios.$get(`setting/website-settings`).then((data) => {
        console.log(data.data);
        commit("SET_BROWSER_INFO", data.data);
      });
    },
  },
};

export default settingsModule;
