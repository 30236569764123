const staticModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      policy: [],
      usage: [],
      aboutUs: [],
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getPolicy: (state) => state.policy,
    getUsage: (state) => state.usage,
    getAboutUs: (state) => state.aboutUs,
  },
  mutations: {
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setPolicy: (state, payload) => {
      state.policy = payload;
    },
    setUsage: (state, payload) => {
      state.usage = payload;
    },
    setAboutUs: (state, payload) => {
      state.aboutUs = payload;
    },
  },
  actions: {
    policyData({ commit }) {
      commit("setIsLoading", true);
      this.$axios
        .$get("static/policy")
        .then((data) => {
          commit("setIsLoading", false);
          commit("setPolicy", data.data);
        })
        .catch((error) => {
          commit("setIsLoading", false);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
    usageData({ commit }) {
      commit("setIsLoading", true);
      this.$axios
        .$get("static/usage")
        .then((data) => {
          commit("setIsLoading", false);
          commit("setUsage", data.data);
        })
        .catch((error) => {
          commit("setIsLoading", false);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
    aboutData({ commit }) {
      this.$axios
        .$get("static/about")
        .then((data) => {
          commit("setAboutUs", data.data);
          commit("setIsLoading", false);
        })
        .catch((error) => {
          commit("setIsLoading", false);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
  },
};

export default staticModule;
