import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const cartModule = {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      cart: [],
      shippingLoading: false,
      preventPay: false,
      checkoutLoading: false,
      fail: false,
      address_message: "",
      coupon_message: "",
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getProductsCart: (state) => state.cart,
    getShippingLoading: (state) => state.shippingLoading,
    getPreventPay: (state) => state.preventPay,
    getAddressMessage: (state) => state.address_message,
    getCouponMessage: (state) => state.coupon_message,
    getIsFail: (state) => state.fail,
    getCheckoutLoading: (state) => state.checkoutLoading,
  },
  mutations: {
    CUSTOM_PRODUCT_COUNT: (state, payload) => {
      console.log(state);
      console.log(payload);
    },

    // Loading
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setProductsCart: (state, payload) => {
      state.cart = payload;
    },
    SET_SHIPPING_LOADING: (state, payload) => {
      state.shippingLoading = payload;
    },
    SET_PREVENT_PAY: (state, payload) => {
      state.preventPay = payload;
    },
    SET_IS_FAIL: (state, payload) => {
      state.fail = payload;
    },
    SET_ADDRESS_NOT_AREA: (state, payload) => {
      state.address_message = payload;
    },
    SET_COUPON_NOT_VAL: (state, payload) => {
      state.coupon_message = payload;
    },
    SET_CHECKOUT_LOADING: (state, payload) => {
      state.checkoutLoading = payload;
    },
  },
  actions: {
    async allProductsCart({ commit }) {
      commit("setIsLoading", true);
      await this.$axios
        .$get("cart/products")
        .then((data) => {
          console.log(data);
          commit("setProductsCart", data.data);
          commit("SET_IS_FAIL", false);
        })
        .catch((error) => {
          if (error.response.status == 406) {
            commit("SET_PREVENT_PAY", true);
            commit("SET_ADDRESS_NOT_AREA", error.response.data.address_message);
            commit("SET_COUPON_NOT_VAL", error.response.data.coupon_message);
            commit("setProductsCart", error.response.data.data);
          }
          commit("SET_IS_FAIL", true);
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    },

    async summeryCart({ commit }, payload) {
      await this.$axios
        .$post("cart/summary", payload)
        .then((data) => {
          console.log(data.data);
          commit("setProductsCart", data.data);
          commit("SET_PREVENT_PAY", false);
          commit("SET_IS_FAIL", false);
          commit("SET_ADDRESS_NOT_AREA", data.address_message);
          commit("SET_COUPON_NOT_VAL", data.coupon_message);
        })
        .catch((error) => {
          if (error.response.status == 406) {
            error;
            commit("SET_IS_FAIL", true);
            commit("SET_PREVENT_PAY", true);
            commit("setProductsCart", error.response.data.data);
            commit("SET_ADDRESS_NOT_AREA", error.response.data.address_message);
            errorAdded(error.response.data.message);

            commit("SET_COUPON_NOT_VAL", error.response.data.coupon_message);
          }
          if (error.response.status == 404) {
            commit("SET_COUPON_NOT_VAL", error.response.data.message);
            commit("SET_IS_FAIL", true);
          }
        });
    },

    async editCart({ commit, state }, payload) {
      await this.$axios
        .$post("cart/edit", payload)
        .then((data) => {
          commit("setProductsCart", data.data);
          successfulAdded(data.message);
        })
        .catch((error) => {
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          if (error.response.status == 406) {
            commit("setProductsCart", state.cart);
            errorAdded(error.response.data.message);
          }
        });
    },
    async deleteProduct({ commit }, payload) {
      await this.$axios
        .$post("cart/delete", payload)
        .then((data) => {
          commit("setProductsCart", data.data);
          successfulAdded(data.message);
        })
        .catch((error) => {
          console.log(error);
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    async syncCart({ commit, state }) {
      let vendors = [];
      let storageCart = [];
      for (var i = 0; i < state.cart.vendors.length; i++) {
        vendors = state.cart.vendors[i];
        for (var x = 0; x < vendors.products.length; x++) {
          storageCart.push({
            product_id: vendors.products[x].id,
            quantity: vendors.products[x].quantity,
          });
        }
      }
      await this.$axios
        .$post("cart/sync", { cart: storageCart })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        })
        .finally(() => {});
    },
    //==============================================
    // CheckOut
    payByCash({ commit }, payload) {
      commit("SET_CHECKOUT_LOADING", true);
      this.$axios
        .$post(`cart/checkout`, payload)
        .then((data) => {
          console.log(data);
          commit("setProductsCart", []);
          if (this.app.i18n.locale == "en") {
            this.app.router.push("/en/?payment=sucess");
          } else {
            this.app.router.push("/?payment=sucess");
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          if (error.response.status == 406) {
            errorAdded(error.response.data.message);
          }
        })
        .finally(() => {
          commit("SET_CHECKOUT_LOADING", false);
        });
    },
    payByVisa({ commit }, payload) {
      commit("SET_CHECKOUT_LOADING", true);

      this.$axios
        .$post(`cart/pay`, payload)
        .then((data) => {
          window.location.replace(data.data.link);
          commit("setLoading", false);
        })
        .catch((error) => {
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          if (error.response.status == 406) {
            errorAdded(error.response.data.message);
          }
        })
        .finally(() => {
          commit("SET_CHECKOUT_LOADING", false);
        });
    },
    payByWallet({ commit }, payload) {
      commit("SET_CHECKOUT_LOADING", true);

      this.$axios
        .$post(`cart/pay-with-wallet`, payload)
        .then((data) => {
          console.log(data);
          commit("setProductsCart", []);
          if (this.app.i18n.locale == "en") {
            this.app.router.push("/en/?payment=sucess");
          } else {
            this.app.router.push("/?payment=sucess");
          }
        })
        .catch((error) => {
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          if (error.response.status == 406) {
            errorAdded(error.response.data.message);
          }
        })
        .finally(() => {
          commit("SET_CHECKOUT_LOADING", false);
        });
    },
    //=========================================
    // Reorder
    async reorderTransiction({ commit }, payload) {
      await this.$axios
        .$post(`order/reorder/${payload}`)
        .then((data) => {
          commit("setProductsCart", data.data);
          successfulAdded(data.message);

          switch (this.$i18n.locale) {
            case "en":
              this.$router.replace(
                "/en/application/checkout/FollowPaymentProcess/"
              );
              break;
            case "ar":
              this.$router.replace(
                "/application/checkout/FollowPaymentProcess/"
              );
              break;
          }
        })
        .catch((error) => errorAdded(error.message));
    },
  },
};

export default cartModule;
