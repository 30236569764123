
import { mapGetters } from "vuex";

import emptyData from "~/components/main/emptyData";
export default {
  name: "not-found",
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      title: this.$i18n.t("meta.notFound.title"),
      titleTemplate: `%s - ${this.browserInfo.site_name}`,
      link: [
        {
          hid: "favicon",
          rel: "icon",
          type: "image/png",
          href: `${this.browserInfo.browser_logo}`,
        },
      ],

      meta: [
        {
          hid: "description",
          name: "description",
          content: `${this.browserInfo.seo_desc}`,
        },
        {
          hid: "keywords",
          name: "keywords",
          content: `${this.browserInfo.seo_keys}`,
        },
      ],
    };
  },

  data() {
    return {
      browserInfo: {},

      emptyData: {
        image: "not-found.png",
        head: this.$i18n.t("Search_again"),

        paragraph: this.$i18n.t(
          "Try_modifying_your_search_to_find_what_you_looking_for"
        ),
        button: {
          url: "",
          name: this.$i18n.t("Search_again"),
        },
      },
    };
  },
  components: {
    emptyData,
  },
  computed: {
    ...mapGetters("settingsModule", ["getBrowserInfo"]),
  },
  mounted() {
    this.browserInfo = this.getBrowserInfo;
  },
  watch: {
    getBrowserInfo() {
      this.browserInfo = this.getBrowserInfo;
    },
  },
};
