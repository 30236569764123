import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const guestModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
  },
  mutations: {
    SET_LOADING: (state, payload) => {
      state.isLoading = payload;
    },
  },
  actions: {
    async generateToken({ commit }) {
      commit("SET_LOADING", false);
      await this.$axios
        .$post("guest/token")
        .then((data) => {
          console.log(data);
          this.$cookies.set("X-Guest-Token", data.data.token);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchCartProducts({ commit }) {
      const guestToken = this.$cookies.get("X-Guest-Token");
      commit("SET_LOADING", false);
      await this.$axios
        .$get("guest/cart/products", {
          headers: {
            "X-Guest-Token": guestToken,
          },
        })
        .then((data) => {
          commit("cartModule/setProductsCart", data.data, { root: true });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async guestEditCart({ commit }, payload) {
      const guestToken = this.$cookies.get("X-Guest-Token");

      await this.$axios
        .$post("guest/cart/edit", payload, {
          headers: {
            "X-Guest-Token": guestToken,
          },
        })
        .then((data) => {
          commit("cartModule/setProductsCart", data.data, { root: true });
          successfulAdded(data.message);
        })
        .catch((error) => {
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          if (error.response.status == 406) {
            errorAdded(error.response.data.message);
          }
        });
    },
    async guestDeleteProduct({ commit }, payload) {
      const guestToken = this.$cookies.get("X-Guest-Token");

      await this.$axios
        .$post("guest/cart/delete", payload, {
          headers: {
            "X-Guest-Token": guestToken,
          },
        })
        .then((data) => {
          commit("cartModule/setProductsCart", data.data, { root: true });
          successfulAdded(data.message);
        })
        .catch((error) => {
          console.log(error);
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
  },
};

export default guestModule;
