export default function ({ store, $axios }) {
  let res;
  $axios.$get(`setting/website-settings`).then((data) => {
    res = data.data;
    store.commit("settingsModule/SET_BROWSER_INFO", res);
  });
  // .finally(() => {
  //   let link = document.querySelector("link[rel~='icon']");
  //   let description = document.querySelector("meta[name=description]");
  //   let keywords = document.querySelector("meta[name=keywords]");
  //   if (!link) {
  //     link = document.createElement("link");
  //     link.rel = "icon";
  //     link.type = "image/png";
  //     document.getElementsByTagName("head")[0].appendChild(link);
  //   }
  //   if (!description) {
  //     description = document.createElement("meta");
  //     description.name = "description";
  //     document.getElementsByTagName("head")[0].appendChild(description);
  //   }
  //   if (!keywords) {
  //     keywords = document.createElement("meta");
  //     keywords.name = "keywords";
  //     document.getElementsByTagName("head")[0].appendChild(keywords);
  //   }
  //   link.href = res.browser_logo;
  //   description.content = res.seo_desc;
  //   keywords.content = res.seo_keys;
  //   document.title = res.site_name;
  // });
}
