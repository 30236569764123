import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const userModule = {
  namespaced: true,
  state() {
    return {
      isAuth: false,
      user: {},
      newUser: {},

      isButtonLoading: false,
      errors: [],
      searchingProduct: [],
    };
  },
  getters: {
    getIsAuth: (state) => state.isAuth,
    getUserInfo: (state) => state.user,
    getNewUserInfo: (state) => state.newUser,
    getIsButtonLoading: (state) => state.isButtonLoading,
    getErrors: (state) => state.errors,
    getSearchingProduct: (state) => state.searchingProduct,
  },
  mutations: {
    setIsAuth: (state, payload) => {
      state.isAuth = payload;
    },
    setUserInfo: (state, payload) => {
      state.user = payload;
    },
    setUpdateUserInfo: (state, payload) => {
      console.log(payload);
      state.newUser[payload.key] = payload.event;
    },
    setUpdateCurrentUserInfo: (state, payload) => {
      console.log(payload);
      state.user[payload.key] = payload.event;
      state.newUser.phone = state.user.phone;
      state.newUser.id = state.user.id;
    },
    setIsButtonLoading: (state, payload) => {
      state.isButtonLoading = payload;
    },
    setError: (state, payload) => {
      state.errors = payload;
    },
    resetUser(store) {
      store.user = null;
    },
    setSearchingProduct(state, payload) {
      state.searchingProduct = payload;
    },
  },
  actions: {
    editUserInfo({ commit }, payload) {
      commit("setIsButtonLoading", true);
      this.$axios
        .$post("auth/update-profile", payload)
        .then((data) => {
          successfulAdded(data.message);
          commit("setIsButtonLoading", false);
          commit("setUserInfo", data.data);
          commit("setError", []);
        })
        .catch((error) => {
          commit("setError", error.response.data.data);
          commit("setIsButtonLoading", false);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
    changeUserLanguage({ commit }, payload) {
      this.$axios
        .$post(`auth/update-language/${payload}`)
        .then(() => {})
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
    userSearching({ commit }, payload) {
      this.$axios
        .$get(`search?q=${payload}`)
        .then((data) => {
          commit("setSearchingProduct", data.data);
          console.log(data);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
  },
};

export default userModule;
