
import { successfulAdded, errorAdded } from "~/plugins/sweetAlert";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      user: {},
    };
  },
  methods: {
    ...mapMutations("userModule", ["setIsAuth", "setUserInfo"]),
    ...mapMutations("cartModule", ["setProductsCart"]),

    ...mapActions("guestModule", ["generateToken"]),

    async handleLogout() {
      try {
        await this.$auth.logout("laravelJWT");
        // Send data To Store
        this.setIsAuth(false);
        this.setUserInfo({});
        this.setProductsCart([]);

        // Alert Success
      } catch (err) {
        console.log(err);
      } finally {
        this.generateToken();
      }
    },
  },
  computed: {
    ...mapGetters("userModule", ["getUserInfo"]),
  },
  mounted() {
    this.user = this.getUserInfo;
  },
  watch: {
    getUserInfo() {
      this.user = this.getUserInfo;
    },
  },
};
