import productsModule from "./modules/productsModule";
import cartModule from "./modules/cartModule";
import categoriesModule from "./modules/categoriesModule";
import userModule from "./modules/userModule";
import commonQuestions from "./modules/commonQuestions";
import addressModule from "./modules/addressModule";
import walletModule from "./modules/walletModule";
import requestsModule from "./modules/requestsModule";
import myOrdersModule from "./modules/myOrdersModule";
import vendorsModule from "./modules/vendorsModule";
import recipesModule from "./modules/recipesModule";
import blogModule from "./modules/blogModule";
import staticModule from "./modules/staticModule";
import sliderModule from "./modules/sliderModule";
import settingsModule from "./modules/settingsModule";
import guestModule from "./modules/guestModule";

export default {
  strict: true,
  state() {
    return {
      requstFail: false,
    };
  },
  getters: {
    getRequstFail: (state) => state.requstFail,
  },
  mutations: {
    setRequstFail: (state, payload) => {
      state.requstFail = payload;
    },
  },
  modules: {
    // Profile
    userModule,
    addressModule,
    walletModule,
    requestsModule,
    myOrdersModule,
    // Application
    productsModule,
    cartModule,
    categoriesModule,
    commonQuestions,
    vendorsModule,
    recipesModule,
    blogModule,
    staticModule,
    sliderModule,
    settingsModule,
    guestModule,
  },
};
