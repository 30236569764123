const recipesModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      recipesTotal: [],
      recipes: [],
      mostRecipes: [],
      recipeDetails: {},
      isMore: false,
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getRecipesTotal: (state) => state.recipesTotal,
    getRecipes: (state) => state.recipes,
    getMostRecipes: (state) => state.mostRecipes,
    getRecipeDetails: (state) => state.recipeDetails,
    getIsMore: (state) => state.isMore,
  },
  mutations: {
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setRecipes: (state, payload) => {
      state.recipes = payload;
    },
    setRecipesTotal: (state, payload) => {
      state.recipesTotal = payload;
    },
    setMostRecipes: (state, payload) => {
      state.mostRecipes = payload;
    },

    setRecipeDetails: (state, payload) => {
      state.recipeDetails = payload;
    },

    //

    SET_MORE_RECIPES: (state, payload) => {
      state.recipes.push(...payload);

      // console.log(payload);
    },
    SET_IS_MORE: (state, payload) => {
      state.isMore = payload;
    },
  },
  actions: {
    recipesList({ commit }) {
      commit("setIsLoading", true);
      this.$axios
        .$get("recipe")
        .then((data) => {
          commit("setIsLoading", false);
          commit("setRecipes", data);
          commit("setRecipes", data.data);
        })
        .catch((error) => {
          commit("setIsLoading", false);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
    recipeById({ commit }, payload) {
      commit("setIsLoading", true);
      this.$axios
        .$get(`recipe/${payload}`)
        .then((data) => {
          commit("setIsLoading", false);
          commit("setRecipeDetails", data.data);
        })
        .catch((error) => {
          commit("setIsLoading", false);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          console.log(error);
        });
    },
    async loadMore({ commit }, payload) {
      return await this.$axios
        .$get(`recipe?page=${payload}`)
        .then((data) => {
          commit("SET_MORE_RECIPES", data.data);
          commit("SET_IS_MORE", data.next);
        })
        .catch((error) => {
          errorAdded(error);
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
  },
};

export default recipesModule;
