import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const myOrdersModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      isButtonLoading: false,
      myOrders: [],
      orderRating: {},
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsButtonLoading: (state) => state.isButtonLoading,
    getMyOrders: (state) => state.myOrders,
    getOrderRating: (state) => state.orderRating,
  },
  mutations: {
    // Loading
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setMyOrders: (state, payload) => {
      state.myOrders = payload;
    },
    SET_IS_BUTTON_LOADING: (state, payload) => {
      state.isButtonLoading = payload;
    },
    SET_ORDER_RATING: (state, payload) => {
      state.orderRating = payload;
    },
    SET_VENDOR_RATING: (state, payload) => {
      state.orderRating.vendors[payload.vendorIndex].rate = payload.rate;
    },
    SET_PRODUCT_RATING: (state, payload) => {
      state.orderRating.vendors[payload.vendorIndex].products[
        payload.productIndex
      ].rate = payload.rate;
    },
    SET_PRODUCT_REVIEW: (state, payload) => {
      state.orderRating.vendors[payload.vendorIndex].products[
        payload.productIndex
      ].review = payload.review;
    },
  },
  actions: {
    async myOrdersList({ commit }) {
      commit("setIsLoading", true);
      await this.$axios
        .$get("order/track-my-orders")
        .then((data) => {
          commit("setMyOrders", data.data);
          commit("setIsLoading", false);
        })
        .catch((error) => {
          errorAdded(error);
          console.log(error);
        });
    },
    async reviewOrder({ commit, state }, payload) {
      commit("SET_IS_BUTTON_LOADING", true);
      return await this.$axios
        .$post(`order/save-order-rate/${payload}`, state.orderRating)
        .then((data) => {
          successfulAdded(data.message);
          commit("SET_IS_BUTTON_LOADING", false);
        })
        .catch((error) => {
          errorAdded(error);
          commit("SET_IS_BUTTON_LOADING", false);
          console.log(error);
        });
    },
  },
};

export default myOrdersModule;
