import { successfulAdded, errorAdded } from "~/plugins/sweetAlert.js";

const addressModule = {
  namespaced: true,
  state() {
    return {
      isLoading: true,
      addresses: [],
      defaultAddress: {},
      selectedAddress: {},
      //
      countriesList: [],
      regionsList: [],
      citiesList: [],
      //
      buttonLoading: false,

      validationErrors: [],
    };
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getUserAddresses: (state) => state.addresses,
    getDefaultAddress: (state) => state.defaultAddress,
    getSelectedAddress: (state) => state.selectedAddress,
    //
    getCountriesList: (state) => state.countriesList,
    getRegionsList: (state) => state.regionsList,
    getCitiesList: (state) => state.citiesList,
    //
    getButtonLoading: (state) => state.buttonLoading,
  },
  mutations: {
    // Loading
    setIsLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setButtonLoading: (state, payload) => {
      state.buttonLoading = payload;
    },
    setDefaultAddress: (state, payload) => {
      state.defaultAddress = payload;
    },
    setUserAddress: (state, payload) => {
      state.addresses = payload;
    },
    setUserNewAddress: (state, payload) => {
      state.addresses.push(payload);
    },
    setSelectedAddress: (state, payload) => {
      state.selectedAddress = payload;
    },
    setEditSelectedAddress: (state, payload) => {
      state.selectedAddress[payload.key] = payload.event;
    },

    setDeleteAddress: (state, payload) => {
      let findIndex = state.addresses.findIndex(
        (address) => address.id === payload
      );
      state.addresses.splice(findIndex, 1);
    },

    //
    SET_COUTRIES_LIST: (state, payload) => {
      state.countriesList = payload;
    },
    SET_REGIONS_LIST: (state, payload) => {
      state.regionsList = payload;
    },
    SET_CITIES_LIST: (state, payload) => {
      state.citiesList = payload;
    },
    SET_CITIES_LIST: (state, payload) => {
      state.citiesList = payload;
    },
  },
  actions: {
    //============================================
    userAddress({ commit }) {
      commit("setIsLoading", true);
      this.$axios
        .$get("address")
        .then((data) => {
          console.log(data);
          commit("setUserAddress", data.data);
          commit("setIsLoading", false);
          let defaultAdd = data.data.filter((address) => address.is_default);
          commit("setDefaultAddress", ...defaultAdd);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addNewAddress({ commit }, payload) {
      commit("setButtonLoading", true);
      return this.$axios
        .$post("address/store", payload)
        .then((data) => {
          commit("setUserNewAddress", data.data);
          var path = location.pathname;
          var directories = path.split("/");
          var lastDirecotry = directories[directories.length - 2];
          if (lastDirecotry == "addAddress") {
            switch (this.$i18n.locale) {
              case "en":
                this.$router.replace("/en/user/address/");
                break;
              case "ar":
                this.$router.replace("/user/address/");
                break;
            }
          }
          successfulAdded(data.message);
        })
        .catch((error) => {
          this.validationErrors = error.response.data.data;
          if (error.response.data.data.phone) {
            errorAdded(error.response.data.data.phone[0]);
          } else {
            errorAdded(error.response.data.message);
          }
          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        })
        .finally(() => {
          commit("setButtonLoading", false);
        });
    },
    defaultAddress({ commit }, payload) {
      this.$axios
        .$post(`address/set-default/${payload}`)
        .then((data) => {
          console.log(data);
          successfulAdded(data.message);

          commit("setUserAddress", data.data);
        })
        .catch((error) => {
          console.log(error);

          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    addressById({ commit }, payload) {
      commit("setIsLoading", true);
      this.$axios
        .$get(`address/show/${payload}`)
        .then((data) => {
          commit("setSelectedAddress", data.data);
          commit("setIsLoading", false);
        })
        .catch((error) => {
          console.log(error);

          console.log(error);

          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    editAddress({ commit }, payload) {
      commit("setButtonLoading", true);
      this.$axios
        .$post("address/update", payload)
        .then((data) => {
          commit("setUserNewAddress", data.data);
          commit("setButtonLoading", false);
          successfulAdded(data.message);

          if (this.app.i18n.locale == "en") {
            this.app.router.push("/en/user/address/");
          } else {
            this.app.router.push("/user/address/");
          }
        })
        .catch((error) => {
          console.log(error);
          commit("setButtonLoading", false);

          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
        });
    },
    deleteAddress({ commit }, payload) {
      this.$axios
        .$post(`address/delete/${payload}`)
        .then((data) => {
          console.log(data);
          commit("setDeleteAddress", payload);
          successfulAdded(data.message);
        })
        .catch((error) => {
          console.log(error);

          if (error.response.status == 500) {
            commit("setRequstFail", true, { root: true });
          }
          if (error.response.status == 422) {
            errorAdded(error.response.data.message);
          }
        });
    },

    fetchCoutriesList({ commit }) {
      return this.$axios
        .$get("address/geo-data")
        .then((data) => {
          console.log(data);
          commit("SET_COUTRIES_LIST", data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

export default addressModule;
