export default function ({ app, $axios, redirect }) {
  $axios.onRequest((config) => {
    config.baseURL = process.env.baseUrl;
    config.headers.common["Content-Type"] = "application/json; charset=UTF-8";
    config.headers.common["Accept"] = "application/json";
    config.headers.common["Access-Control-Allow-Origin"] = "*";
    config.headers.common["content-encoding"] = "gzip";
    config.headers.common["Cache-Control"] =
      "must-revalidate, public, max-age=3600";
    config.headers.common["lang"] = app.i18n.locale || "ar";
    config.headers.common["shipping"] = localStorage.getItem("country") || "";
    config.headers.common["Access-Control-Allow-Headers"] =
      "X-Requested-With, content-type";
    if (app.$auth.loggedIn)
      config.headers.common["Authorization"] = app.$auth.strategy.token.get();
  });

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status);

    if (code === 400) {
      // redirect("/400");
      console.log(error);
    }
  });
}
